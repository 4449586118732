import React, { Component } from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

import styles from "./title.module.sass";

let cx = classNames.bind(styles);

class Title extends Component {
    render = () => {
        const {
            color,
            children,
            type,
            mb,
            mt,
            pt,
            transform,
            className,
        } = this.props;
        let classes = cx({
            [color]: true,
            [transform]: transform,
            [className]: !!className,
        });
        let Type = type;

        return (
            <Type
                className={classes}
                style={{
                    marginBottom: `${mb}px`,
                    marginTop: `${mt}px`,
                    paddingTop: `${pt}px`,
                }}
            >
                {children}
            </Type>
        );
    };
}

Title.propTypes = {
    pt: PropTypes.number,
    mt: PropTypes.number,
    mb: PropTypes.number,
    transform: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.oneOf(["h1", "h2", "h3", "h4"]).isRequired,
    color: PropTypes.oneOf([
        "gold",
        "blueDark",
        "grayBlueDark",
        "white",
        "platinum",
        "silver",
        "goldNegative",
    ]).isRequired,
};

Title.defaultProps = {
    pt: 0,
    mt: 0,
    mb: 0,
    type: "h2",
    color: "gold",
    transform: "",
};

export default Title;
