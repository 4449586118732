import React from "react";
import style from "./thankyou.module.sass";

import button from "../../components/Button/button.module.sass";
import { Link } from "gatsby";

const ThankYouWidget = ({ typeLabel, typeLink }) => (
    <>
        <p className={style.paragraph}>
            {typeLabel && typeLabel}
            <br />
            <br />
            Těšíme se na vás.
            <br />
            <br />
            <Link
                to={typeLink ? typeLink : "/"}
                className={button.buttonOutlineBlueDark}
            >
                Rozumím
            </Link>
        </p>
    </>
);

export default ThankYouWidget;
