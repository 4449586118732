import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import style from "./buttonBar.module.sass";
import Button from "../../components/Button";

import { TYPE_GOLD } from "../../constants";

const NOT_VISIBLE = "notVisible";
const NOT_STICKY = "notSticky";
const STICKY = "sticky";

const ButtonBar = ({ formButtonLabel, formButtonLink, type }) => {
    const barRef = useRef(null);
    const [displayState, setDisplayState] = useState(NOT_VISIBLE);
    const [buttonRef, setButtonRef] = useState(null);

    useEffect(() => {
        let button = document.getElementById("contactButton");
        setButtonRef(button);

        if (!button) {
            setDisplayState(STICKY);
        }
    }, []);

    useEffect(() => {
        function handleScroll() {
            let scrollTop =
                document.body.scrollTop || document.documentElement.scrollTop;
            let height =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;

            if (
                buttonRef &&
                buttonRef.getBoundingClientRect().top + buttonRef.offsetHeight >
                    0
            ) {
                setDisplayState(NOT_VISIBLE);
            } else if (
                scrollTop + height >
                barRef.current.offsetTop + barRef.current.offsetHeight
            ) {
                setDisplayState(NOT_STICKY);
            } else {
                setDisplayState(STICKY);
            }
        }

        window.addEventListener("scroll", handleScroll);

        return function cleanup() {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    let barInnerClass = classNames({
        [style.notVisible]: displayState === NOT_VISIBLE,
        [style.sticky]: displayState === STICKY,
        [style.barInner]: true,
    });

    return (
        <div className={style.bar} ref={barRef}>
            <div className={barInnerClass}>
                {type === TYPE_GOLD && (
                    <Button uri="/vyvoj-cen/" type="link" color="outline-gold">
                        Spočítat investice
                    </Button>
                )}

                <Button
                    uri={formButtonLink}
                    type="link"
                    color={type ? type : TYPE_GOLD}
                >
                    {formButtonLabel}
                </Button>
            </div>
        </div>
    );
};

export default ButtonBar;
