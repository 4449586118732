import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Header from "./SiteHeader";
import Footer from "./SiteFooter";
import ButtonBar from "./ButtonBar";
import "../styles/global.sass";
import style from "../styles/content.module.sass";
import { isSilverCoinTheme } from "../utils";

const SiteLayout = ({
    children,
    location,
    type,
    formButtonLabel,
    formButtonLink,
    is404,
    coin,
    coinTheme,
    bgImage,
    checkout,
    eshop,
}) => (
    <React.Fragment>
        <div
            className={classNames({
                [style.background]: type,
                [style.backgroundCoin]: coin,
            })}
            style={
                coin && {
                    backgroundImage: `url(${bgImage})`,
                    backgroundColor: isSilverCoinTheme(coinTheme)
                        ? "#013748"
                        : "#00212F",
                }
            }
        >
            <Header
                location={location}
                coin={coin}
                checkout={checkout}
                eshop={eshop}
            />
            <div className={!is404 ? style.appWrapper : null}>{children}</div>
            {formButtonLabel && formButtonLink && (
                <ButtonBar
                    type={type}
                    formButtonLabel={formButtonLabel}
                    formButtonLink={formButtonLink}
                />
            )}
        </div>
        <Footer />
    </React.Fragment>
);

SiteLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SiteLayout;
