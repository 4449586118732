import React from "react";
import Seo from "../components/Seo";
import SiteLayout from "../components/SiteLayout";

import Breadcrumb from "../components/BreadCrumb/";
import Title from "../components/Title/";
import Block from "../components/Block/";
import ThankYou from "../components/ThankYou";

export default class ThankYouPlatinumPage extends React.Component {
    render() {
        return (
            <SiteLayout location={this.props.location}>
                <Seo
                    title="Pomůžeme s investicí do platiny"
                    description="Díky za zájem o investice do platiny. Brzy vás budeme kontaktovat s nabídkou na míru."
                />
                <Breadcrumb
                    crumbs={[{ label: "Děkujeme", link: "thank-you-platinum" }]}
                />
                <Block>
                    <Title type={"h1"} color={"gold"} mb={40}>
                        Díky za váš zájem
                        <br />o investováni do platiny.
                    </Title>
                </Block>
                <Block radialGradient={true}>
                    <ThankYou
                        typeLabel="V blízké době vás bude kontaktovat s detailní nabídkou vhodnou pro vás. Popřípadě se spolu podíváme na jiné možnosti investování do platiny."
                        typeLink="/investice-do-platiny/"
                    />
                </Block>
            </SiteLayout>
        );
    }
}
