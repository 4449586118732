import React, { Component } from "react";
import classNames from "classnames/bind";
import { Link } from "gatsby";

import styles from "./breadcrumb.module.sass";
import Block from "../Block";
import PropTypes from "prop-types";

let cx = classNames.bind(styles);

class Breadcrumb extends Component {
    render = () => {
        const {
            mt,
            mb,
            color,
            leftIndent = true,
            fullWidth = false,
        } = this.props;

        const bredClass = cx({
            bradcrumb: true,
            bradcrumbWhite: color === "white",
        });
        const lastItem = cx({
            item: true,
            current: true,
        });
        return (
            <Block leftIndent={leftIndent} fullWidth={fullWidth}>
                <ul
                    className={bredClass}
                    style={{ paddingTop: `${mt}px`, marginBottom: `${mb}px` }}
                >
                    <li className={styles.item}>
                        <Link to="/">Homepage</Link>
                    </li>
                    {this.props.crumbs.map((crumb, index) =>
                        this.props.crumbs.length - index > 1 ? (
                            <li key={index} className={lastItem}>
                                <Link to={crumb.link}>{crumb.label}</Link>
                            </li>
                        ) : (
                            <li key={index} className={styles.noLink}>
                                {crumb.label}
                            </li>
                        )
                    )}
                </ul>
            </Block>
        );
    };
}

Breadcrumb.propTypes = {
    mt: PropTypes.number,
    color: PropTypes.string,
};

Breadcrumb.defaultProps = {
    mt: 0,
    mb: 30,
    color: null,
};

export default Breadcrumb;
