import React, { Component } from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./button.module.sass";

let cx = classNames.bind(styles);

class Button extends Component {
    render = () => {
        const { color, width, children, mt, type, uri, state } = this.props;
        let button = cx({
            button: true,
            buttonBlueDark: color === "blue-dark",
            buttonGold: color === "gold",
            buttonOutlineGold: color === "outline-gold",
            buttonOutlineGoldDark: color === "outline-gold-dark",
            buttonSilver: color === "silver",
            buttonOutlineSilver: color === "outline-silver",
            buttonPlatinum: color === "platinum",
            buttonGrey: color === "grey",
            buttonGrayBlueDark: color === "gray-blue-dark",
            buttonOutlineBlueDark: color === "button-outline-blue-dark",
            widthFull: width === "full",
            buttonWidthBig: width === "big",
            buttonWidthBigOrder: width === "order",
            buttonBuy: width === "buy",
            buttonContact: width === "contact",
        });
        return type === "link" ? (
            <Link
                to={uri}
                className={button}
                state={state}
                style={{ marginTop: `${mt}px` }}
            >
                {children}
            </Link>
        ) : (
            <button
                {...this.props}
                className={button}
                style={{ marginTop: `${mt}px` }}
            >
                {children}
            </button>
        );
    };
}

Button.propTypes = {
    mt: PropTypes.number,
    color: PropTypes.oneOf([
        "gold",
        "blue-dark",
        "button-outline-blue-dark",
        "outline-gold",
        "outline-gold-dark",
        "grey",
        "gray-blue-dark",
        "silver",
        "outline-silver",
        "platinum",
    ]),
    width: PropTypes.oneOf([
        "nominal",
        "full",
        "big",
        "order",
        "buy",
        "contact",
    ]),
    type: PropTypes.string,
    uri: PropTypes.string,
};

Button.defaultProps = {
    mt: 0,
    color: "gold",
    width: "nominal",
    type: "button",
    uri: "#",
};

export default Button;
